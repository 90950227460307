import classNames from "classnames"
import HeroOrderingForm from "components/landingpage/HeroOrderingForm"
import { useShopStatus } from "hooks/firestore/advanced/useShopStatus"
import { useShop } from "hooks/firestore/simple/useShop"

export default function Hero() {
  const shop = useShop()
  const shopStatus = useShopStatus()

  return (
    <section
      className={`tw-bg-cover tw-bg-top tw-py-32`}
      style={{
        backgroundImage: shop?.landingpage?.hero?.image && `url(${shop.landingpage.hero.image.url})`,
      }}
    >
      <div className="tw-mx-auto tw-h-full tw-flex tw-flex-col tw-items-center tw-justify-center tw-gap-4">
        {shop?.landingpage?.specialElements?.bannerInHero?.active &&
          shop?.landingpage.specialElements.bannerInHero.text && (
            <div
              className={classNames(
                "tw-rounded-2xl tw-p-8 tw-shadow-2xl tw-max-w-2xl tw-flex tw-flex-col tw-items-center",
                shop?.landingpage?.hero?.blurBackground ? "tw-backdrop-blur-xl" : ""
              )}
              style={{
                backgroundColor: shop?.landingpage?.hero?.backgroundColor
                  ? `rgba(${parseInt(shop.landingpage.hero.backgroundColor.slice(1, 3), 16)}, ${parseInt(
                      shop.landingpage.hero.backgroundColor.slice(3, 5),
                      16
                    )}, ${parseInt(shop.landingpage.hero.backgroundColor.slice(5, 7), 16)}, ${
                      shop.landingpage.hero.backgroundOpacity ?? 0.15
                    })`
                  : `rgba(255, 255, 255, ${shop?.landingpage?.hero?.backgroundOpacity ?? 0.15})`,
              }}
            >
              <p
                className="tw-text-pretty tw-text-xl tw-text-center tw-text-white tw-mb-0 tw-prose tw-whitespace-pre-wrap"
                style={{ color: shop?.landingpage?.specialElements?.bannerInHero?.textColor || "#FFFFFF" }}
              >
                {shop.landingpage.specialElements.bannerInHero.text}
              </p>
            </div>
          )}
        <div
          className={classNames(
            "tw-rounded-2xl tw-p-8 tw-shadow-2xl tw-max-w-2xl tw-flex tw-flex-col tw-items-center",
            shop?.landingpage?.hero?.blurBackground ? "tw-backdrop-blur-xl" : ""
          )}
          style={{
            backgroundColor: shop?.landingpage?.hero?.backgroundColor
              ? `rgba(${parseInt(shop.landingpage.hero.backgroundColor.slice(1, 3), 16)}, ${parseInt(
                  shop.landingpage.hero.backgroundColor.slice(3, 5),
                  16
                )}, ${parseInt(shop.landingpage.hero.backgroundColor.slice(5, 7), 16)}, ${
                  shop.landingpage.hero.backgroundOpacity ?? 0.15
                })`
              : `rgba(255, 255, 255, ${shop?.landingpage?.hero?.backgroundOpacity ?? 0.15})`,
          }}
        >
          <h1
            className="tw-max-w-5xl tw-font-bold tw-mb-4 tw-text-center tw-text-balance"
            style={{ color: shop?.landingpage?.hero?.titleColor || "#FFFFFF" }}
          >
            {shop?.landingpage?.hero?.title || "Herzlich Willkommen bei " + shop?.name}
          </h1>
          <p
            className="tw-text-xl tw-mb-6 tw-text-center tw-text-balance"
            style={{ color: shop?.landingpage?.hero?.descriptionColor || "#FFFFFF" }}
          >
            {shop?.landingpage?.hero?.description}
          </p>
          {!shopStatus.loading && !shopStatus.shopIsOpen && (
            <p className="tw-text-balance tw-w-full tw-text-xl tw-text-white tw-font-bold tw-bg-red-700 tw-p-4 tw-mb-6 tw-text-center tw-rounded-lg">
              {shop?.customClosedShopMessage && shopStatus.shopClosedReason === "manual"
                ? shop.customClosedShopMessage
                : "Wir haben gerade geschlossen. Nutze doch die Zeit und stöbere ein bisschen."}
            </p>
          )}
          <HeroOrderingForm />
        </div>
      </div>
    </section>
  )
}
